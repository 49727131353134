import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';  // Main page component

import Chat from './pages/Chat/Chat';
import Chatroom from './pages/Chatroom/Chatroom';
import LandingPage from './pages/LandingPage/LandingPage';
import Login from './pages/Login/Login';
import Register from './pages/Login/Register';
import MyPlan from './pages/MyPlan/MyPlan';
import People from './pages/People/People';
import Profile from './pages/Profile/Profile';
import Settings from './pages/Settings/Settings';
import './i18n';  // Make sure to import i18n here to initialize it globally



import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';  // Import routing tools

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />  {/* Main page route */}
        <Route path="/chat" element={<App />} />  {/* Second page route */}
        <Route path="/chatroom" element={<Chatroom />} />  {/* Second page route */}
        <Route path="/people" element={<People />} />  {/* Second page route */}
        <Route path="/profile" element={<Profile />} />  {/* Second page route */}
        <Route path="/myplan" element={<MyPlan />} />  {/* Second page route */}
        <Route path="/settings" element={<Settings />} />  {/* Second page route */}
        <Route path="/login_page" element={<Login />} />  {/* Second page route */}
        <Route path="/register_page" element={<Register />} />  {/* Second page route */}
        <Route path="/landingpage" element={<LandingPage />} />  {/* Second page route */}
      </Routes>
    </Router>
  </React.StrictMode>
);

// Performance reporting (optional)
reportWebVitals();
