// src/Login.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Login.css';  // Ensure this file is created for styling
import axios from 'axios';
import { useTranslation } from 'react-i18next';  // Import the hook for automatic system language detection

function Login() {
  const { t } = useTranslation();  // Destructure t function to access translations
  const [accountName, setAccountName] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();  // Initialize useNavigate
  let local_url = "https://yoohi.ai";
  const handleLogin = (e) => {
    e.preventDefault();
    axios.post(`${local_url}/api/login`, { accountName, password }) // Sending accountName with password
      .then(response => {
        // log out if you are guest status
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('accountName');

        localStorage.setItem('token', response.data.token);  // Store JWT token
        localStorage.setItem('accountName', accountName);  // Store accountName
        localStorage.setItem('username', response.data.username);  // Store username
        navigate('/');  // Redirect to dashboard or another protected page
        window.location.reload();
      })
      .catch(error => {
        alert(t('Invalid credentials'));  // Use i18n for alert
      });
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h1>{t('Login')}</h1> {/* Replace hardcoded "Login" with i18n */}
        <form onSubmit={handleLogin}>
          <input
            type="text"
            placeholder={t('User ID')}  // Replace placeholder with i18n
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder={t('Password')}  // Replace placeholder with i18n
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit" className="login-btn">{t('Login')}</button>  {/* Replace button text with i18n */}
        </form>

        <div className="link-container">
          <Link to="/register_page" className="link-btn">
            {t("Don't have an account? Register")}  {/* Replace link text with i18n */}
          </Link>
          <Link to="/" className="link-btn">
            {t('Go back to Main Page')}  {/* Replace link text with i18n */}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
