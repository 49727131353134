// src/Register.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Register.css';  // Ensure this file is created for styling
import axios from 'axios';
import { useTranslation } from 'react-i18next'; // Import the hook for automatic system language detection

function Register() {
  const { t } = useTranslation();  // Destructure t function to access translations
  const [username, setUsername] = useState('');
  const [accountName, setAccountName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  let local_url = "https://yoohi.ai";
  const handleRegister = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert(t("Passwords don't match!"));  // Use t() for the alert message
      return;
    }

    axios.post(`${local_url}/api/register`, {
      accountName,
      username,
      email,
      password
    })
      .then(response => {
        alert(t('User registered successfully'));  // Use t() for the success message
        navigate('/login_page');  // Redirect to login page
      })
      .catch(error => {
        alert(error.response?.data?.error || t('Registration failed'));  // Use t() for the error message
      });
  };

  return (
    <div className="register-container">
      <div className="register-box">
        <h1>{t('Register')}</h1>  {/* Use t() for the heading */}
        <form onSubmit={handleRegister}>
          <input
            type="text"
            placeholder={t('Nickname')}  // Use t() for the placeholder
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder={t('User ID (for login)')}  // Use t() for the placeholder
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder={t('Password')}  // Use t() for the placeholder
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder={t('Confirm Password')}  // Use t() for the placeholder
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder={t('Email Address')}  // Use t() for the placeholder
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" className="register-btn">{t('Register')}</button>  {/* Use t() for the button label */}
        </form>

        <div className="link-container">
          <Link to="/" className="link-btn">{t('Go back to Main Page')}</Link>  {/* Use t() for the link */}
        </div>
      </div>
    </div>
  );
}

export default Register;
