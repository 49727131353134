import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import RecordRTC from 'recordrtc';
import { v4 as uuidv4 } from 'uuid'; // Import uuid
import './Chat.css'; // Import the CSS file for chat styling
import convertUtcToLocal from'../../utils/convertUTCtoCurrent'; // Import the CSS file for chat styling
import Compressor from 'compressorjs';
import { useTranslation } from 'react-i18next'; // Import the hook for automatic system language detection



const CryptoJS = require('crypto-js');
const local_url = "https://yoohi.ai";
// const local_url = process.env.REACT_APP_BACKEND_URL || "http://localhost:5002";
const local_frontend_url ="http://localhost:3000";

//const local_frontend_url = "https://yoohi.ai";

// let local_url = "https://192.168.50.202:5002";
// let local_frontend_url = "https://192.168.50.202:3000";
let time_remaining = 60;
let publicroom_ID = "ID001";
const socket = io(local_url);

function Chat() {
  const { t, i18n } = useTranslation();  // Destructure t function to access translations
  const systemLanguage = navigator.language.split('-')[0]; // Get the ISO-639-1 language code
  const [isRecording, setIsRecording] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [localAudioUrl, setLocalAudioUrl] = useState(null);
  const [recorder, setRecorder] = useState(null); // State to manage RecordRTC object
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [userId, setUserId] = useState(null); // State to store the UID
  const [username, setUsername] = useState(''); // State to store the username
  const [translations, setTranslations] = useState([]); // State to store translations
  const [selectedLanguageOther, setSelectedLanguageOther] = useState(''); // for the selected language in the box
  const [selectedLanguageMe, setSelectedLanguageMe] = useState(''); // for the selected language in the box
  const [visibleTranslations, setVisibleTranslations] = useState({});
  const [visibleOriginals, setVisibleOriginals] = useState({});

  const [message, setMessage] = useState(''); // For the text input
  const [photoFile, setPhotoFile] = useState(null);
  const [localPhotoUrl, setLocalPhotoUrl] = useState(null);

  //recording button countdown
  const [timeRemaining, setTimeRemaining] = useState(time_remaining); // 60 seconds countdown
  const [timerInterval, setTimerInterval] = useState(null); // For the countdown interval
  const recordButtonRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false); // Track fullscreen state


    // Function to handle language change from the dropdown
    const handleLanguageChange = (event) => {
      const selectedLanguage = event.target.value;
      i18n.changeLanguage(selectedLanguage);  // Change language dynamically
    };
  
  // Handle photo selection or taken photo
  const handlePhotoChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
        const photoUrl = URL.createObjectURL(file);
        setLocalPhotoUrl(photoUrl);
        setPhotoFile(file); 

        new Compressor(file, {
            quality: 0.6, // Compress image to 60% of the original quality
            success(result) {
                sendPhotoToServer(result).then((interpretation) => {
                    setTranslations(prev => [...prev, {
                        userId: userId,
                        username: username,
                        original_text:  photoUrl,
                        translated_text: interpretation,
                        content_type: 'photo',
                        timestamp: new Date().toISOString(),

                    }]);
                });
            },
            error(err) {
                console.log(err.message);
            }
        });
    }
};

  const sendPhotoToServer = (file) => {
    const formData = new FormData();
    formData.append('userId', localStorage.getItem('userId'));
    formData.append('chatroomId', publicroom_ID);  // Hardcoded chatroomId for now
    formData.append('photo', file, CryptoJS.MD5(Date.now() + Math.random().toString()).toString().concat(".jpeg"));
  
    return axios.post(`${local_url}/api/upload-message-photo`, formData)
      .then(response => {
        console.log('Photo interpretation:', response.data);
         fetchTranslations(publicroom_ID); // Fetch updated translations for chatroom
        return response.data.translation;  // Adjust this if you return the photo URL from the server
      })
      .catch(error => {
        console.error(t('Error fetching photo interpretation:'), error);
      });
  };

  const handleSendMessage = () => {
    if (message.trim() !== '') {
      axios.post(`${local_url}/api/upload-message-text`, {
        userId: userId,
        username: username,
        message: message,
        chatroomId: publicroom_ID  // Hardcoded chatroom for now
      })
      .then(response => {
        setMessage(''); // Clear input after sending
        fetchTranslations(publicroom_ID); // Fetch updated translations for chatroom
      })
      .catch(error => {
        console.error(t('Error sending message:'), error);
      });
    }
  };

useEffect(() => {
    // Check if a UID exists in localStorage
    let storedUserId = localStorage.getItem('accountName');
    let storedUsername = localStorage.getItem('username');

    if (true) { // temporarily change for usablle
    // if (!storedUserId || !storedUsername) {
        // If no UID, generate a new one for first-time guest users
        
        console.log("fuck yeaaaaaa")
        const newUserId = uuidv4();
        const newUsername = `User_${newUserId.slice(0, 4)}`; // Example: "User_ab12"

        // Automatically register the guest user
        axios.post(`${local_url}/api/register`, {
            accountName: newUserId,  // Use the generated UUID as the account name
            username: newUsername,
            email: `${newUserId}@guest.com`,  // Using a default email for guest users
            password: newUserId  // For guest users, use the same UUID as password
        })
        .then(() => {
            // After successful registration, log in the user automatically
            return axios.post(`${local_url}/api/login`, {
                accountName: newUserId,
                password: newUserId  // Log in with the same UUID
            });
        })
        .then(response => {
            // Store the token, accountName, and username in localStorage
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('accountName', newUserId);
            localStorage.setItem('username', newUsername);
            
            // Set userId and username in the state
            setUserId(newUserId);
            setUsername(newUsername);
            
            // Fetch translations after successful login
            fetchTranslations(publicroom_ID);
        })
        .catch(error => {
            console.error('Error registering or logging in the guest user:', error);
        });
    } else {
        // If userId and username exist, fetch past translations
        setUserId(storedUserId);
        setUsername(storedUsername);
        fetchTranslations(publicroom_ID);
    }
    
    // WebSocket listener for real-time translations
    socket.on('new_translation', (newTranslation) => {
        setTranslations(prev => [...prev, {
            username: newTranslation.username,
            original_text: newTranslation.original_text,
            translated_text: newTranslation.translated_text,
            content_type: newTranslation.content_type,
            timestamp: newTranslation.timestamp,
            userId: newTranslation.userId
        }]);
    });

    // Cleanup WebSocket listener on component unmount
    return () => {
        socket.off('new_translation');
    };
}, []);




  // get all translation
  //@app.route('/api/all-translations', methods=['GET'])
  //def get_all_translations():
  const fetchTranslations = (chatroomId) => {
    axios.get(`${local_url}/api/all-translations/${chatroomId}`)
      .then(response => {
        setTranslations(response.data.translations);  // Set the translations in state
      })
      .catch(error => {
        console.error(t('Error fetching all translations:'), error);
      });
  };

  useEffect(() => {
    const isTouchCapable = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    setIsTouchDevice(isTouchCapable);
    // Set the default language based on the system's language

    switch(systemLanguage) {
      case 'zh':
        setSelectedLanguageOther('Chinese');
        setSelectedLanguageMe('Chinese');
        break;
      case 'ja':
        setSelectedLanguageOther('Japanese');
        setSelectedLanguageMe('Japanese');
        break;
      case 'en':
        setSelectedLanguageOther('English');
        setSelectedLanguageMe('English');
        break;
      case 'ar':
        setSelectedLanguageOther('Arabic');
        setSelectedLanguageMe('Arabic');
        break;
      default:
        setSelectedLanguageOther('English'); 
        setSelectedLanguageMe('English'); /// Default to English if unsupported
        break;
    }
  }, [systemLanguage]);

// Extract text based on the selected language
const extractTextByLanguage = (translatedText, language) => {
    const languageTags = {
        Chinese: '[Chinese]',
        English: '[English]',
        Japanese: '[Japanese]',
        Arabic: '[Arabic]'
    };
    const startTag = languageTags[language];
    const regex = new RegExp(`\\${startTag}(.*?)\\[`, 's'); // regex to capture text until the next bracket
    const match = translatedText.match(regex);
    return match ? match[1].trim() : 'No content available';
    };
const languageOptions = ['Chinese', 'English', 'Japanese', 'Arabic'];
    

  // useEffect to handle side effects based on `isRecording` state
  useEffect(() => {
    if (isRecording) {
      startRecording(); // Start recording when `isRecording` is true
    } else if (recorder) {
      stopRecording(); // Stop recording when `isRecording` is false
    }
  }, [isRecording]); // Trigger when `isRecording` changes

    
const startRecording = () => {
        
        setIsRecording(true);
        setTimeRemaining(time_remaining); // Reset the timer
        console.log("B")
        console.log(isRecording);
        
        // Start the countdown timer
        const interval = setInterval(() => {
          setTimeRemaining((prev) => {
            if (prev <= 1) {
              console.log("A")
              console.log(isRecording);
              setIsRecording(false); // Set recording to false when timer hits zero
              clearInterval(interval);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
    

        setTimerInterval(interval); // Save the interval for later clearing
    

        navigator.mediaDevices.getUserMedia({ audio: true })
          .then((stream) => {
            const options = {
              mimeType: 'audio/webm',
              numberOfAudioChannels: 1,
              recorderType: RecordRTC.StereoAudioRecorder,
              checkForInactiveTracks: true,
              timeSlice: 1000,
              ondataavailable: (blob) => {
                // Emit the audio blob via the socket
                socket.emit('audio', { buffer: blob });
              },
            };
    
            const recordRTC = new RecordRTC(stream, options);
            setRecorder(recordRTC);
            recordRTC.startRecording();
            

          })
          .catch(error => {
            console.error('Error accessing microphone:', error);
            setIsRecording(false);
          });




};

  const stopRecording = () => {
    console.log("C")
    console.log(isRecording);


    if (recorder) {
      console.log("D")
      console.log(isRecording);
      recorder.stopRecording(() => {
        const audioBlob = recorder.getBlob();
        const audioUrl = URL.createObjectURL(audioBlob);
        setLocalAudioUrl(audioUrl);
        sendAudioToServer(audioBlob);
        setIsRecording(false); // Update recording state
        recorder.destroy(); // Optional: Clear recorder object
        setRecorder(null);
        clearInterval(timerInterval); // Stop the countdown
        
      });
      
    }

    // setIsRecording(false);


  };

  const toggleRecording = () => {
    setIsRecording(!isRecording); 
  };
 
  const sendAudioToServer = (audioBlob) => {

    const formData = new FormData();
    formData.append('userId', userId);  // Include userId
    formData.append('chatroomId', publicroom_ID);  // Hardcoded chatroomId for now
    formData.append('audio', audioBlob, 'recording.mp3');
  
    axios.post(`${local_url}/api/upload-message-audio`, formData)
      .then(response => {
        console.log('Audio uploaded successfully.');
        fetchTranslations(publicroom_ID);  // Fetch updated translations for the specific chatroom
      })
      .catch(error => {
        console.error('Error uploading audio:', error);
      });
  };
  

  // Clear History Button
  const clearHistory = () => {
    axios.delete(`${local_url}/api/clear-history/${publicroom_ID}`)
      .then(response => {
        console.log('History cleared successfully.');
        setTranslations([]); // Clear the translations state
      })
      .catch(error => {
        console.error('Error clearing history:', error);
      });
  };
  

  // this is for the right button
  const toggleTranslationVisibility = (index) => {
    setVisibleTranslations(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const toggleOriginalVisibility = (index) => {
    setVisibleOriginals(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  socket.on('transcription', (data) => {
    setTranscript(data.text);
  });



  // Function to toggle fullscreen
  const toggleFullScreen = () => {
    if (!isFullScreen) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.webkitRequestFullscreen) { // Safari
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) { // IE11
        document.documentElement.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { // Safari
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { // IE11
        document.msExitFullscreen();
      }
    }
    setIsFullScreen(!isFullScreen);
  };



  return (
    <div>
      {/* Recording button */}
      <button
        ref={recordButtonRef}
        onClick={!isTouchDevice ? toggleRecording : undefined} // Use toggleRecording for click events
        onTouchStart={isTouchDevice ? toggleRecording : undefined} // Use toggleRecording for touch events
        className={`floating-record-btn ${isRecording ? 'recording' : ''}`}
      >
        {isRecording ? `${t('Stop')} \n`.concat(timeRemaining) : t('Speak')}
        <svg className="record-timer" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="45" />
        </svg>
      </button>
  
      {/* <button
        className="floating-fullscreen-btn"
        onClick={toggleFullScreen}
      >
        {isFullScreen ? 'Exit Fullscreen' : 'Fullscreen'}
      </button> */}
  
      {/* Language selection dropdowns */}
      <div className="dropdowns-container">
        <div className="select-container-left">
          <select
            className="select-language-left"
            onChange={(e) => setSelectedLanguageOther(e.target.value)}
            value={selectedLanguageOther}
          >
            {languageOptions && languageOptions.length > 0 && languageOptions.map((lang) => (
              <option key={lang} value={lang}>
                {t(lang)} {/* Use i18n for language options */}
              </option>
            ))}
          </select>
        </div>
        {/* <div className="select-container-right">
          <select
            className="select-language-right"
            onChange={(e) => setSelectedLanguageMe(e.target.value)}
            value={selectedLanguageMe}
          >
            {languageOptions.map((lang) => (
              <option key={lang} value={lang}>
                {t(lang)} 
              </option>
            ))}
          </select>
        </div> */}
      </div>
  
      {/* Chat bubbles */}
      <div className="chat-container">
        {translations && translations.length > 0 ? (
          translations.map((translation, index) => (
            <div key={index} className={`message-container ${translation.username === username ? 'right' : 'left'}`}>
              {/* Avatar and Username */}
              <div className="avatar-container">
                {/* Avatar Image */}
                <img
                  src={translation.avatar ? translation.avatar : local_frontend_url.concat('/').concat("user_avatar/default_avatar_1.png")} //slice 7 for using the public folder to get
                  alt={`${translation.username}'s avatar`}
                  className="avatar-image"
                  onClick={() => window.location.href = `/profile/${translation.username}`} // Redirect on avatar click
                />
                {/* Username */}
                <p className="username-text">{translation.username}</p>
              </div>
  
              {/* Chat Bubble */}
              <div className={`chat-bubble ${translation.username === username ? 'right' : 'left'}`}>
                {translation.content_type === 'photo' ? (
                  <>
                    {/* Handling photo bubble */}
                    <div className="photo-bubble">
                      <img
                        src={local_frontend_url.concat('/').concat(translation.original_text.slice(7))}
                        alt={local_frontend_url.concat('/').concat(translation.original_text.slice(7))}
                        className="chat-photo"
                      />
                    </div>
                    <p>{translation.translated_text}</p>
                    <p className="timestamp">
                      <strong>{t('Sent at:')} </strong> {convertUtcToLocal(translation.timestamp)}
                    </p>
                  </>
                ) : (
                  <>
                    {/* Handling text bubbles */}
                    {translation.username !== username ? (
                      <>
                        <p className={`text-section translated-text left`}>
                          <span style={{ display: 'block' }}>
                            {extractTextByLanguage(translation.translated_text, selectedLanguageOther)}
                          </span>
                        </p>
                        {visibleOriginals[index] && (
                          <p className={`text-section original-text left`}>
                            <strong>{t('Original Text')}:</strong>{' '}
                            <span style={{ display: 'block' }}>{translation.original_text}</span>
                          </p>
                        )}
                        <button className="chat-bubble-btn" onClick={() => toggleOriginalVisibility(index)}>
                          {visibleOriginals[index] ? t('Hide Original Text') : t('Show Original Text')}
                        </button>
                      </>
                    ) : (
                      <>
                        <p className={`text-section original-text right`}>
                          <span style={{ display: 'block' }}>{translation.original_text}</span>
                        </p>
                        {visibleTranslations[index] && (
                          <p className={`text-section translated-text right`}>
                            <strong>{t('Translated Text')}:</strong>{' '}
                            <span style={{ display: 'block' }}>
                              {extractTextByLanguage(translation.translated_text, selectedLanguageMe)}
                            </span>
                          </p>
                        )}
                        <button className="chat-bubble-btn" onClick={() => toggleTranslationVisibility(index)}>
                          {visibleTranslations[index] ? t('Hide Translated Text') : t('Show Translated Text')}
                        </button>
                      </>
                    )}
                    <p className="timestamp">
                      <strong>{t('Sent at:')} </strong> {convertUtcToLocal(translation.timestamp)}
                    </p>
                  </>
                )}
              </div>
            </div>
          ))
        ) : (
          <p>{t('No messages to display')}</p>
        )}
      </div>
  
      {/* Clear history button */}
      <button onClick={clearHistory}>
        {t('Clear History')}
      </button>
  
      {/* Empty space */}
      <div className="empty-space"></div>
  
      {/* Text input for messages */}
      <div className="text-box-container">
        <input
          type="text"
          placeholder={t('Type a message...')}
          className="text-input"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSendMessage();
            }
          }}
        />
        <button className="send-btn" onClick={handleSendMessage}>
          {t('Send')}
        </button>
      </div>
  
      {/* Photo selection button */}
      <label className="floating-photo-btn">
        {t('Photo')}
        <input
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handlePhotoChange}
        />
      </label>
  
      {/* Optionally, preview the selected photo */}
      {/* {localPhotoUrl && (
        <div className="photo-preview">
          <img src={localPhotoUrl} alt={t('Selected')} style={{ maxWidth: '200px', marginTop: '10px' }} />
        </div>
      )} */}
      
      {/* Language Selection Dropdown */}
      <div className="language-selector">
        <label htmlFor="language-select">{t('Select UI Language')}: </label>
        <select id="language-select" onChange={handleLanguageChange}>
          <option value="en">English</option>
          <option value="zh">中文</option>
          <option value="ja">日本語</option>
          <option value="ar">العربية</option>
          <option value="es">Español</option>
          <option value="fr">Français</option>
          <option value="ru">Русский</option>
          <option value="pt">Português</option>
          <option value="de">Deutsch</option>
          <option value="hi">हिन्दी</option>
          <option value="bn">বাংলা</option>
          <option value="ko">한국어</option>
          <option value="it">Italiano</option>
          <option value="tr">Türkçe</option>
          <option value="vi">Tiếng Việt</option>
          <option value="nl">Nederlands</option>
        </select>
      </div>
  
      <div className="empty-space"></div>
      <div className="empty-space"></div>
      <div className="empty-space"></div>
      <div className="empty-space"></div>
      <div className="empty-space"></div>
      <div className="empty-space"></div>
      <div className="empty-space"></div>
      <div className="empty-space"></div>
    </div>
  );
}

export default Chat;