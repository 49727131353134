import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Logout.css';
import { useTranslation } from 'react-i18next';  // Import the hook for i18n

function Logout() {
  const { t } = useTranslation();  // Destructure t function to access translations
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('accountName');
    alert(t('Logged out successfully'));  // Use i18n translation for the alert message
    navigate('/login_page');  // Redirect to login page
  };

  return (
    <button onClick={handleLogout} className="logout-btn">
      {t('Logout')}  {/* Use i18n translation for the button text */}
    </button>
  );
}

export default Logout;
