// src/App.js
import React, { useEffect, useRef, useState } from 'react';
import Chat from './pages/Chat/Chat';
import { Link, useNavigate } from 'react-router-dom';  // Import Link for navigation
import { QRCodeCanvas } from 'qrcode.react';
import './styles/AppQRCodeStyle.css'; // Import the CSS file for chat styling
import './styles/AppRecorderStyle.css'; // Import the CSS file for chat styling
import './styles/AppSideBarStyle.css'; // Import the CSS file for chat styling
import './App.css'; // Import the CSS file for chat styling
import Logout from './pages/Login/Logout';

import { useTranslation } from 'react-i18next'; // Import the hook for automatic system language detection

function App() {
  const { t } = useTranslation();  // Destructure t function to access translations
  const url = window.location.href;
  const qrContainerRef = useRef(null);
  
  const [backgroundHeight, setBackgroundHeight] = useState('0px');
  const [scale, setScale] = useState(1);
  const [yScale, setYScale] = useState(1); // Scale for the background along Y-axis
  const [transformOrigin, setTransformOrigin] = useState("50% 50%"); // Default transform origin
  const [showSidebar, setShowSidebar] = useState(false);

  const navigate = useNavigate();  // Initialize the useNavigate hook

  useEffect(() => {
    if (qrContainerRef.current) {
      const { bottom } = qrContainerRef.current.getBoundingClientRect();
      setBackgroundHeight(`${bottom + 20}px`);  // 20px below the QR code
    }}, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScrollVertical);
    return () => window.removeEventListener('scroll', handleScrollVertical);
  }, []);

  const handleScrollVertical = () => {
    // This function is for the QR code sliding function
    const position = window.pageYOffset;
    const newScale = Math.max(0.001, 1 - position / 50); // Adjust scale base on scroll, minimum scale is 0.5
    const newYScale = Math.max(0.001, 1 - position / 50); // Slower scale reduction for Y axis
    const newTransformOrigin = position > 300 ? "50% 10%" : "50% 50%";
    console.log(`Scroll position: ${position}, Scale: ${newScale}, Transform Origin: ${newTransformOrigin}`);

    setScale(newScale);
    setYScale(newYScale);
    setTransformOrigin(newTransformOrigin);
  };

  const toggleSidebar = () => {
    // This function is for side bar State
    setShowSidebar(!showSidebar);
  };

  const handleSidebarLoginClick = () => {
    navigate('/login_page');  // Programmatically navigate to /login_page when Login is clicked
  };
  const handleSidebarChatClick = () => {
    navigate('/');  // Programmatically navigate to /
  };
  const handleSidebarChatroomClick = () => {
    navigate('/chatroom');  // Programmatically navigate to /chatroom
  };
  const handleSidebarPeopleClick = () => {
    navigate('/people');  // Programmatically navigate to /people
  };
  const handleSidebarProfileClick = () => {
    navigate('/profile');  // Programmatically navigate to /profile
  };
  const handleSidebarMyPlanClick = () => {
    navigate('/myplan');  // Programmatically navigate to /myplan
  };
  const handleSidebarSettingClick = () => {
    navigate('/setting');  // Programmatically navigate to /setting
  };
  const handleSidebarWebsiteClick = () => {
    navigate('/landingpage');  // Programmatically navigate to /landingpage
  };

  return (
    <div className="App">
      <button className="button-top-right" onClick={toggleSidebar}>
        {t('Menu')} {/* Replacing hardcoded "Menu" */}
      </button>
      <Logout />
      
      <div className={showSidebar ? "sidebar open" : "sidebar"}>
        <ul>
          <li onClick={handleSidebarChatClick} style={{ cursor: 'pointer' }}>{t('Chat')}</li>
          <li onClick={handleSidebarChatroomClick} style={{ cursor: 'pointer' }}>{t('Chatrooms')}</li>
          <li onClick={handleSidebarProfileClick} style={{ cursor: 'pointer' }}>{t('Profile')}</li>
          {/* Uncomment other items as needed */}
          {/* <li onClick={handleSidebarPeopleClick} style={{ cursor: 'pointer' }}>{t('People')}</li> */}
          {/* <li onClick={handleSidebarMyPlanClick} style={{ cursor: 'pointer' }}>{t('My Plan')}</li> */}
          {/* <li onClick={handleSidebarSettingClick} style={{ cursor: 'pointer' }}>{t('Settings')}</li> */}
          {/* <li onClick={handleSidebarWebsiteClick} style={{ cursor: 'pointer' }}>{t('Website')}</li> */}
          <li onClick={handleSidebarLoginClick} style={{ cursor: 'pointer' }}>{t('Login')}</li>
        </ul>
      </div>

      <div className="background-layer" style={{ transform: `scaleY(${yScale})` }}></div>
      <div className="centered-content" style={{ transform: `scale(${scale})`, transformOrigin: transformOrigin }}>
        <div className="text-container">
          <p>{t('Scan this QR code to join the session.')}</p> {/* Replacing the hardcoded text */}
        </div>
        <div className="qr-container">
          <QRCodeCanvas value={url} />
        </div>
      </div>
      <div className="recorder-container">
        <Chat />
      </div>
    </div>
  );
}

export default App;
